import React from "react";
import { StaticQuery, graphql } from "gatsby";

import Layout from "../../components/layout";
import Seo from "../../components/seo";
import Header from "../../components/header";

import Tabs from "../../components/tabsWhoWeAre";

const History = () => {
  return (
    <Layout>
      <Seo title="Historia" />
      <Header />
      <Tabs selection="historia" />
      <main className="who-we-are">
        <p>
          El Centro Farmacéutico del Norte (Cenfarte) fue fundado en 1942 con la
          determinación de mejorar y optimizar el abastecimiento de medicamentos
          a las farmacias. Actualmente el grupo cuenta con más de 100 empleados,
          1000 socios y una red próxima a las 4000 farmacias.
        </p>
        <div className="history">
          <div className="timeline">
            <StaticQuery
              query={graphql`
                {
                  allHistoryJson {
                    edges {
                      node {
                        history {
                          milestone
                          text
                          year
                        }
                      }
                    }
                  }
                }
              `}
              render={(data) =>
                data.allHistoryJson.edges[0].node.history.map(
                  (value, index) => {
                    return (
                      <div className="timeline__event" key={index}>
                        <div className="timeline__event__date">
                          {value.year}
                        </div>
                        <div className="timeline__event__content ">
                          <div className="timeline__event__title">
                            {value.milestone}
                          </div>
                          <div className="timeline__event__description">
                            <p>{value.text}</p>
                          </div>
                        </div>
                      </div>
                    );
                  }
                )
              }
            />
          </div>
        </div>
      </main>
    </Layout>
  );
};

export default History;
